import { SerializedDGSPoolConfig } from 'state/deblox/types'
import { serializeTokens } from '../tokens'
import { PoolCategory } from '../types'

const serializedTokens = serializeTokens()

const pools: SerializedDGSPoolConfig[] = [
  {
    sousId: 5,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0xe3D210A9EC008047BC11A4E80c120ee366cc100b',
      56: '0x497184a43a45940f3B42f1949BaB46eE35991eD7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    isLPPool: false,
    isPremium: true
  },
  {
    sousId: 4,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0x7E870925fAa5F9713a88AC248F04073cceD07903',
      56: '0x497184a43a45940f3B42f1949BaB46eE35991eD7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    isLPPool: false,
    isPremium: true
  },
  {
    sousId: 3,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0xfcAE383ca71eEE14a074ed9B5938D88E2a623235',
      56: '0x497184a43a45940f3B42f1949BaB46eE35991eD7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: true,
    isLPPool: false,
    isPremium: true
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0x47aFfE900873F612941733200b34124422068857',
      56: '0x497184a43a45940f3B42f1949BaB46eE35991eD7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: true,
    isLPPool: false,
    isPremium: true
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0x0Fe195c28F18BB1b835732A7Db2AC4561ff46C5c',
      56: '0x497184a43a45940f3B42f1949BaB46eE35991eD7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    isLPPool: false,
    isPremium: false
  },
  {
    sousId: 0,
    stakingToken: serializedTokens.dgs,
    earningToken: serializedTokens.dgsg,
    contractAddress: {
      97: '0xC6fe4530b403f2397F8a6E7D6aCed8D54912f481',
      56: '0x27C762241f17a1C5253A0Db2e35550F9d7232091',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: true,
    isLPPool: false
  }
]

export default pools
