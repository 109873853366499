import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/deblox/pools'
// eslint-disable-next-line import/no-cycle
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'
// eslint-disable-next-line import/no-cycle
import { useLpTokenPrice } from 'state/farms/hooks'
import pools from 'state/pools'
import { AppThunk } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
// eslint-disable-next-line import/no-cycle
import { multiplyPriceByAmount } from 'utils/prices'
import { DGSPoolsState, SerializedDGSPool } from '../types'
import { fetchFarmsAllowance, fetchUserBalances, fetchUserDepositedTimes, fetchUserPendingRewards, fetchUserStakeBalances } from './fetchDGSPoolsUser'
import { fetchPoolsBlockLimits, fetchPoolsTotalStaking, fetchPoolsWithdrawalFee } from './fetchPools'

const initialState: DGSPoolsState = {
  data: [...poolsConfig],
  dataLoaded: false,
  userDataLoaded: false,
  dgsVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalCakeInVault: null,
    estimatedCakeBountyReward: null,
    totalPendingCakeHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      cakeAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

// Thunks
// This DGS farm is fixed to DGS / BNB
export const fetchDGSFarmsPublicDataAsync = (currentBlock: number) => async (dispatch, getState) => {
  const blockLimits = await fetchPoolsBlockLimits()
  const totalStakings = await fetchPoolsTotalStaking()
  const withdrawalFees = await fetchPoolsWithdrawalFee()
  // const lpTokenPrice = useLpTokenPrice("CAKE-BNB LP")
  // const bnbTokenPrice = multiplyPriceByAmount(useBNBBusdPrice(), 1)

  const liveData = poolsConfig.map((pool) => {
    const blockLimit =  blockLimits.find((entry) => entry.sousId === pool.sousId)
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
    const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded
    const fees = withdrawalFees.find((entry) => entry.sousId === pool.sousId)

    // const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
    // const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    // const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
    // const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 

    
    // const apr = !isPoolFinished
    //   ? getPoolApr(
    //       lpTokenPrice.toNumber(),
    //       bnbTokenPrice,
    //       getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
    //       parseFloat(pool.tokenPerBlock),
    //     )
    //   : 0

    return {
      ...blockLimit,
      ...totalStaking,
      totalStaked: totalStaking.totalStaked,
      // lpTokenPrice,
      // bnbTokenPrice,
      // apr,
      isFinished: isPoolFinished,
      withdrawFee: fees?.withdrawFee,
      withdrawFeePeriod: fees?.withdrawFeePeriod
    }
  })

  console.log('---livedata', liveData)
  dispatch(setDGSFarmsPublicData(liveData))
}

export const fetchDGSPoolsUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchFarmsAllowance(account)
    const stakingTokenBalances = await fetchUserBalances(account)
    const stakedBalances = await fetchUserStakeBalances(account)
    const pendingRewards = await fetchUserPendingRewards(account)
    const lastDepositedTimes = await fetchUserDepositedTimes(account)

    const userData = poolsConfig.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
      lastDepositedTime: lastDepositedTimes[pool.sousId]
    }))

    // console.log('----userData', userData)
    dispatch(setPoolsUserData(userData))
  }

export const DGSPoolsSlice = createSlice({
    name: 'DGSPools',
    initialState,
    reducers: {
      setDGSFarmsPublicData: (state, action) => {
        const livePoolsData: SerializedDGSPool[] = action.payload
        state.data = state.data.map((pool) => {
          const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
          return { ...pool, ...livePoolData }
        })
        state.dataLoaded = true
      },
      setPoolsUserData: (state, action) => {
        const userData = action.payload
        state.data = state.data.map((pool) => {
          const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
          // console.log('----userPoolData in reducer', userPoolData)
          return { ...pool, userData: userPoolData, lastDepositedTime: userPoolData?.lastDepositedTime }
        })
        state.userDataLoaded = true
      },
      updatePoolsUserData: (state, action) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)
  
        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    },
    extraReducers: (builder) => {
      // Vault public data that updates frequently
    //   builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<CakeVault>) => {
    //     state.cakeVault = { ...state.cakeVault, ...action.payload }
    //   })
    //   // Vault fees
    //   builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<VaultFees>) => {
    //     const fees = action.payload
    //     state.cakeVault = { ...state.cakeVault, fees }
    //   })
    //   // Vault user data
    //   builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<VaultUser>) => {
    //     const userData = action.payload
    //     userData.isLoading = false
    //     state.cakeVault = { ...state.cakeVault, userData }
    //   })
    },
  })
  
  // Actions
  export const { setDGSFarmsPublicData, setPoolsUserData } = DGSPoolsSlice.actions
  
  export default DGSPoolsSlice.reducer