import { SlotConfigProps } from "state/deblox/types"

const slots: SlotConfigProps[] = [
  {
    id: 1,
    address: {
      97: "0x441Ff738A17B7d306A91Bf521916B06806ef7A1b",
      56: "0xDC5Bf36581f19BCc35F7c1C21CB6c267eD93F291"
    }
  }, 
  {
    id: 2,
    address: {
      97: "0x7A2a6276390b4e22dCaF14D9a32E12477b69b051",
      56: "0x21Cc613306cca6a884FABd358eABe5eD536ecA1E"
    }
  }, 
  {
    id: 3,
    address: {
      97: "0x7F7f30909A38845C3D71B31CEBd11332DD60FCDa",
      56: "0x8f734fE77EFa3F49CAF3C99Ea161aF1702e2e3dC"
    }
  }, 
  {
    id: 4,
    address: {
      97: "0xEcE024efDD7bd479eF7F3a23Bb321532a5e1DD49",
      56: "0xe63644b9716FD6Dc929F5B139e307d6614423901"
    }
  }, 
  {
    id: 5,
    address: {
      97: "0xA0bEfed7eF1834234323c594107a4504A3b2A0c2",
      56: "0xaD134963c3D412Ce6dE8CdB90B6503d137Ed0cB9"
    }
  }, 
  {
    id: 6,
    address: {
      97: "0x2732366dc218aa859431a6096c76946a64f231cb",
      56: "0x8e60bBd96b88bf977760A777ad6C6Af69Db28E16"
    }
  }, 
  {
    id: 7,
    address: {
      97: "0x8e241521217F473D97c7e3152a667eD07b0d4D39",
      56: ""
    }
  }, 
  {
    id: 8,
    address: {
      97: "0x39ca595D1553F17eD4596D62ABc6603f74D23DdF",
      56: ""
    }
  }, 
  {
    id: 9,
    address: {
      97: "0x2Cffd22Dc0CF2469fbDa4290c0296dc21c8fBA2f",
      56: ""
    }
  }, 
  {
    id: 10,
    address: {
      97: "0xe2531D87Fc34e253ac16f1F22a6e8a80AF4B9C61",
      56: ""
    }
  }, 
  {
    id: 11,
    address: {
      97: "0x9b81765b450b7A6Ea9d1a22Cee2CD8Af592CfbC7",
      56: ""
    }
  }
]

export default slots
